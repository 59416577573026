<template>
  <div>
    <b-form-group
      label="Nombre:"
      label-for="input-name"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-name"
        v-model="$v.learning_experience.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción:"
      label-for="input-description"
      label-class="mt-0"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="evaluation-form-description rich-text-content mr-0 mt-1"
        v-html="$v.learning_experience.description.$model"
        @click="
          $bvModal.show(
            `edit-learning_experience-description-modal-${learning_experience.id}`
          )
        "
      ></div>
      <b-modal
        :id="`edit-learning_experience-description-modal-${learning_experience.id}`"
        title="Editar Descripción"
        size="lg"
        hide-footer
        no-enforce-focus
      >
        <NewRichTextEditor
          :Object="learning_experience"
          :Text="learning_experience.description"
          @save="patchDescription"
          @close="
            $bvModal.hide(
              `edit-learning_experience-description-modal-${learning_experience.id}`
            )
          "
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="institution && institution.internal_use_id == 'ciisa_uss'"
      label-class="mt-0"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      label="Horas Sugeridas:"
      label-for="input-suggested_hours"
      class="label my-2 mx-0"
    >
      <b-form-input
        id="input-suggested_hours"
        type="number"
        min="0"
        v-model="$v.learning_experience.suggested_hours.$model"
        :state="validateState('suggested_hours')"
        aria-describedby="input-suggested_hours-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-suggested_hours-feedback"
        >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="show_competence_unit"
      :label="`${$getVisibleNames(
        'teaching.tempcompetenceunit',
        false,
        'Unidad de competencia'
      )}:`"
      label-for="select-competence-unit"
      label-cols="0"
      label-cols-sm="3"
      label-class="ml-1"
    >
      <b-form-select
        v-if="competenceUnits.length > 0"
        id="select-ra-micro"
        v-model="learning_experience.temp_competence_units"
        size="sm"
        multiple
        class="select-form mt-2"
        @change="changeCompetenceUnit"
        :select-size="competenceUnits.length > 8 ? 8 : competenceUnits.length"
      >
        <b-form-select-option
          class="select-option-micro"
          v-for="u_c in competenceUnits"
          :value="u_c.id"
          :key="u_c.id"
        >
          N° {{ u_c.order }}: {{ u_c.full_sentence }}
        </b-form-select-option>
      </b-form-select>
      <template v-else>
        <div class="mt-1">N/A</div>
      </template>
    </b-form-group>
    <b-form-group
      :label="`${$getVisibleNames('teaching.ramicro', false, 'RA Micro')}:`"
      label-for="select-ra-micro"
      label-cols="0"
      label-cols-sm="3"
      label-class="ml-1"
    >
      <b-form-select
        v-if="studyUnitsList.length > 0"
        id="select-ra-micro"
        v-model="learning_experience.study_units"
        size="sm"
        multiple
        class="select-form mt-2"
        :select-size="studyUnitsList.length > 8 ? 8 : studyUnitsList.length"
      >
        <b-form-select-option
          class="select-option-micro"
          v-for="ecm in studyUnitsList"
          :value="ecm.id"
          :key="ecm.id"
        >
          N°
          {{
            ecm.competence_unit_order != 999
              ? ecm.competence_unit_order + "."
              : ""
          }}{{ ecm.order }}: {{ ecm.full_sentence }}
        </b-form-select-option>
      </b-form-select>
      <template v-else>
        <div class="mt-1">N/A</div>
      </template>
    </b-form-group>
    <b-form-group
      v-if="institution && institution.internal_use_id == 'duoc_uc'"
      :label="`Evaluaciones:`"
      label-for="select-evaluation"
      label-cols="0"
      label-cols-sm="3"
      label-class="ml-1"
    >
      <b-form-select
        v-if="filterMattersEvaluationsWithoutActivity.length > 0"
        id="select-evaluation"
        v-model="matter_evaluations_ids"
        size="sm"
        multiple
        class="select-form mt-2"
        @change="changeStudyUnits"
        :select-size="
          filterMattersEvaluationsWithoutActivity.length > 8
            ? 8
            : filterMattersEvaluationsWithoutActivity.length
        "
      >
        <b-form-select-option
          class="select-option-micro"
          v-for="eva in filterMattersEvaluationsWithoutActivity"
          :value="eva.id"
          :key="eva.id"
        >
          {{ eva.name }}
          <template
            v-if="getStudyUnits(eva.evaluation_criterias_micro).length > 0"
          >
            -
            {{
              getStudyUnits(eva.evaluation_criterias_micro)
                .map((x) => "RA" + x.order)
                .join(", ")
            }}
          </template>
        </b-form-select-option>
      </b-form-select>
      <template v-else>
        <div class="mt-1">N/A</div>
      </template>
    </b-form-group>
    <b-form-group
      v-if="!isNaN(this.learning_experience.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="learning_experience"></ModificationDate>
    </b-form-group>
    <div style="text-align: right">
      <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import {
  required,
  minLength,
  minValue,
  numeric,
  //   maxValue,
} from "vuelidate/lib/validators";
import { generateUniqueId } from "@/utils/utils";
export default {
  name: "LearningExperienceForm",
  mixins: [validationMixin],
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    // allows_crud: {
    //   type: Boolean,
    // },
    matter_id: {
      type: Number,
      required: true,
    },
    max_order: {
      type: Number,
      required: true,
    },
    learning_experiences: {
      type: Array,
    },
    unique_ra: {
      type: Boolean,
      default: false,
    },
    show_competence_unit: {
      type: Boolean,
      default: false,
    },
    LearningExperience: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          order: this.max_order,
          study_units: [],
          matter: this.matter_id,
          temp_competence_units: [],
          suggested_hours: 0,
          updated_by: null,
          updated_date: null,
        };
      },
    },
  },
  data() {
    return {
      learning_experience: { ...this.LearningExperience },
      matter_evaluations_ids: [],
    };
  },
  validations: {
    learning_experience: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {},
      order: {},
      suggested_hours: { minVlaue: minValue(0), numeric },
      matter: {
        required,
      },
      study_units: {},
    },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      studyUnits: names.STUDY_UNITS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      matterEvaluations: names.MATTER_EVALUATIONS,
      competence_units: names.COMPETENCE_UNITS,
    }),
    filterMatterEvaluations() {
      if (!this.matter_id) return [];
      return this.matterEvaluations
        .filter((x) => x.matter == this.matter_id)
        .sort((a, b) => a.order - b.order);
    },
    competenceUnits() {
      if (!this.matter_id) return [];
      return this.competence_units.filter((x) => x.matter == this.matter_id);
    },
    studyUnitsList() {
      let list = this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .map((x) => {
          const competence_unit = this.competenceUnits.find(
            (c) => c.id == x.temp_competence_unit
          );
          return {
            ...x,
            competence_unit_order: competence_unit
              ? competence_unit.order
              : 999,
          };
        })
        .sort(function (a, b) {
          if (a.competence_unit_order > b.competence_unit_order) return 1;
          if (a.competence_unit_order < b.competence_unit_order) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
      if (
        this.show_competence_unit &&
        this.learning_experience.temp_competence_units.length > 0
      )
        list = list.filter(
          (x) =>
            this.learning_experience.temp_competence_units.includes(
              x.temp_competence_unit
            ) || this.learning_experience.study_units.includes(x.id)
        );
      if (this.unique_ra == true) {
        const study_unit_ids = [
          ...new Set(
            this.learning_experiences
              .filter((x) => x.id != this.learning_experience.id)
              .map((x) => x.study_units)
              .reduce((acc, val) => acc.concat(val), [])
          ),
        ];
        return list.filter((x) => !study_unit_ids.includes(x.id));
      } else return list;
    },
    evaluationCriteriasMicrosList() {
      const study_units_ids = this.studyUnitsList.map((st) => st.id);
      return this.evaluationCriteriasMicros.filter((x) =>
        study_units_ids.includes(x.study_unit)
      );
    },
    filterMattersEvaluationsWithoutActivity() {
      if (isNaN(this.learning_experience.id)) {
        // para filtra las matters_evaluation que no sean actividades.
        return this.filterMatterEvaluations
          .filter(
            (x) => x.type != 4 && x.type != 5 && x.learning_experience == null
          )
          .sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
      } else {
        // para filtra las matters_evaluation que no sean actividades.
        return this.filterMatterEvaluations
          .filter(
            (x) =>
              x.type != 4 &&
              x.type != 5 &&
              (x.learning_experience == this.learning_experience.id ||
                x.learning_experience == null)
          )
          .sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
      }
    },
  },
  methods: {
    changeStudyUnits(matter_evaluations_ids) {
      let evaluation_criterias = [];
      this.filterMatterEvaluations
        .filter((x) => matter_evaluations_ids.includes(x.id))
        .forEach((matter_evaluation) => {
          evaluation_criterias = evaluation_criterias.concat(
            matter_evaluation.evaluation_criterias_micro
          );
        });
      this.learning_experience.study_units = this.getStudyUnits([
        ...new Set(evaluation_criterias),
      ]).map((x) => x.id);
    },
    getStudyUnits(evaluation_criterias_micro) {
      const study_units_ids = [
        ...new Set(
          this.evaluationCriteriasMicrosList
            .filter((x) => evaluation_criterias_micro.includes(x.id))
            .map((x) => x.study_unit)
        ),
      ];
      return this.studyUnitsList.filter((x) => study_units_ids.includes(x.id));
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.learning_experience[name];
      return $dirty ? !$error : null;
    },
    patchDescription(object, text) {
      this.learning_experience.description = text;
    },
    save() {
      this.$v.learning_experience.$touch();
      if (this.$v.learning_experience.$anyError) {
        return;
      }
      if (isNaN(this.learning_experience.id)) this.create();
      else this.update();
    },
    create() {
      this.$emit(
        "created",
        this.learning_experience,
        this.matter_evaluations_ids
      );
    },
    update() {
      this.$emit(
        "updated",
        this.learning_experience,
        this.matter_evaluations_ids
      );
    },
    changeCompetenceUnit(competence_units) {
      const study_unit_ids = this.studyUnitsList
        .filter((x) => competence_units.includes(x.temp_competence_unit))
        .map((x) => x.id);
      this.learning_experience.study_units = study_unit_ids.filter((x) =>
        this.learning_experience.study_units.includes(x)
      );
    },
  },
  mounted() {
    if (!isNaN(this.learning_experience.id)) {
      this.matter_evaluations_ids = this.filterMatterEvaluations
        .filter(
          (x) =>
            x.type != 4 &&
            x.type != 5 &&
            x.learning_experience == this.learning_experience.id
        )
        .map((x) => x.id);
    }
  },
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.evaluation-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.select-form {
  display: block !important;
}
.select-option-micro {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
}
</style>